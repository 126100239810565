export const messages: Record<string, string> = {
    "upload.button.cta": "Add New Property",
    "view.more.cta": "View More Properties",
    "roles.super": "As a Super User you can see more information than any other users. You can: add, edit and delete properties/users/groups",
    "roles.owner": "As a owner of your group - you can add more users if you wish to do so",
    "roles.uploader": "As an uploader you are able to add/edit and delete groups/users and properties",
    "property.table.search": "Search for property name here",
    "property.modal.title": "Create new Property",
    "goto.login.page": "Go back to login",
    "button.delete": "Delete Selected",
    "button.group.add": "Add Group",
    "group-management.title": "Group Management",
    "group.form.title": "Group Title",
    "email.invite.title": "Invite a new user",
    "user.manual.title": "Manually add a User",
    "group.manual.title": "Manually add a Group",
    "user.form.name": "Enter the name of the user",
    "user.form.username": "Enter their username",
    "group.form.name": "Enter the name of the Group",
    "group.form.description": "Enter the description",
    "user.recent.title": "Recent Users",
    "group.recent.title": "Recent Groups",
    "email.invite.description": "This send an email with a link which will allow the user to set their own password",
    "group-management.group.add.header": "Add a new Group",
    "user.page.title": "User Management",
    "user.page.add": "Add a new User",
    "no.image": "Image Unable to Load",
    "groups-management.list": "List of Existing Groups",
    "placeholder.properties.title": "No properties",
    "placeholder.properties.subtitle": "There are no properties to show currently",
    "placeholder.users.title": "No users yet!",
    "placeholder.users.subtitle": "There are no users added to your group yet!",
    "dashboard.added-properties": `Recently Uploaded`,
    "login.title": "Welcome",
    "login.subtitle": "Please sign in with your credentials",
    "login.no-account": "Don't have an account?",
    "login.forgotten-password": "Oops! It looks like you may have forgotten your password. Please email or call HD Virtual Art to reset it",
    "login.inactive-account": "Please call us on +44134234234234 or email us to get your account activated.",
    "login.form.forgotten-password": "Forgot your password?",
    "forgotten-password.description": "Enter your email address to reset your password. You may need to check your spam folder. If you don't recieve an email then please let us know.",
    "login.form.username": "Please enter your email address",
    "login.form.password": "Please enter your password",
    "user.form.email": "Enter their email address",
    "user.form.password": "Add a password",
    "forgotten-password.title": "Forgot Your Password?",
    "forgotten-password.form.email": "Please enter your email address",
    "forgotten-password.form.submitted": "If a Dropbox account exists for jerome.nelson@skelia.com, an e-mail will be sent with further instructions"
}